import { Power3, TimelineLite } from 'gsap'
import React, { useEffect, useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import Close from '../assets/Close'
import { device } from '../assets/Styles'

const PopupWrapper = styled.div.attrs({
  className: 'popup-wrapper',
})`
  z-index: 1100;
  opacity: 0;
  visibility: hidden;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  .container {
    height: 100%;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    @media ${device.tablet} {
      padding: 2rem;
    }

    .close-wrapper {
      display: flex;
      justify-content: center;
      width: 100%;
    }

    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  h1 {
    font-family: 'Noto Serif', serif;
    @media ${device.tablet} {
      font-size: 2rem;
    }
    text-align: center;
  }

  p {
    font-family: 'Murecho', sans-serif;
    text-align: center;
    font-size: 1.07rem;
    @media ${device.tablet} {
      font-size: 1.1rem;
    }
  }

  a {
    text-decoration: underline;
    cursor: pointer;
    margin-top: 1rem;
  }
`

const Confirmation = () => {
  const wrapperRef = useRef(null)

  useEffect(() => {
    const timeline = new TimelineLite()
    timeline.to(
      wrapperRef.current,
      0.5,
      {
        ease: Power3.easeInOut,
        autoAlpha: 0.98,
      },
      '+=5',
    )
  }, [])

  const closePopUp = () => {
    const timeline = new TimelineLite()
    timeline.to(wrapperRef.current, 0.4, {
      ease: Power3.easeInOut,
      autoAlpha: 0,
    })
  }

  return (
    <PopupWrapper ref={wrapperRef}>
      <div className="container">
        <div className="content">
          <h1>
            <FormattedMessage id="welcome.closing.h1" />
          </h1>
          <p>
            <FormattedMessage id="welcome.closing.p1" />
          </p>
          <p>
            <FormattedMessage id="welcome.closing.p2" />
          </p>
          <p>
            <FormattedMessage id="welcome.closing.p3" />
          </p>
          <p>
            <FormattedMessage id="welcome.closing.p4" />
          </p>
          <p>
            <FormattedMessage id="welcome.closing.p5" />
          </p>
        </div>
        <div className="close-wrapper">
          <Close color="black" close={closePopUp} />
        </div>
      </div>
    </PopupWrapper>
  )
}

export default React.memo(Confirmation)
