import React from 'react'
import { Select } from 'antd'
import { useIntl } from 'react-intl'

const SelectWithFormik = ({ field, form, people = [] }) => {
  const intl = useIntl()

  const handleChange = (value) => {
    // Find the selected object in the people array
    const selectedPerson = people.find((person) => person.value === value)
    form.setFieldValue(field.name, selectedPerson || null)
    // Manually trigger validation after setting the value
    form.setFieldTouched(field.name, true, false)
  }

  const handleBlur = () => {
    // Ensure touched state is properly set
    form.setFieldTouched(field.name, true)
  }

  return (
    <>
      <Select
        id={field.name}
        name={field.name}
        onBlur={handleBlur}
        onChange={handleChange}
        value={form.values[field.name]?.value} // Access the value property of the selected object
        placeholder={intl.formatMessage({ id: 'reserve.people.placeholder' })}
        className="react-select"
        showSearch={false}
      >
        {people.map((person) => (
          <Option key={person.value} value={person.value}>
            {person.label}
          </Option>
        ))}
      </Select>
    </>
  )
}

export default SelectWithFormik
