import React from 'react'
import styled from 'styled-components'
import { colors, media } from '../../../assets/Styles'
import { FormattedDate, FormattedTime, useIntl } from 'react-intl'

const HighlightStyled = styled.div`
  position: relative;
  z-index: 2;
  &:before {
    content: '';
    z-index: -1;
    display: block;
    height: 90%;
    width: 100%;
    margin-left: -3px;
    margin-right: -3px;
    position: absolute;
    background: #fff2ae;
    transform: rotate(2deg);
    top: -1px;
    left: -1px;
    border-radius: 20% 25% 20% 24%;
    padding: 10px 3px 3px 10px;
  }
`
const Styling = styled.div.attrs({
  className: 'acknowledge-container',
})`
  padding: 2rem 6rem 4rem;

  ${media.phone`
     padding: 0;
  `}
  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 1rem 0 3rem 0;
    h1 {
      text-align: center;
      ${media.phone`
        font-size: 230%;
      `}
    }
    .info {
      padding: 1rem;
      background: #fff;
      margin: 0;

      ${media.phone`
        margin: 0 0 2rem 0;
      `}
      a {
        border-bottom: 1px dotted;
      }
      p {
        text-align: center;
        font-size: 100%;
      }
    }
  }
  p {
    ${media.phone`
      font-size: 120%;
    `}
    font-size: 140%;
    margin: 1rem 0;
    color: ${colors.dark1};

    span {
      color: ${colors.dark0};
      border-bottom: 1px dotted;
    }
  }
`

export default function Acknowledge({ values }) {
  const intl = useIntl()

  const peopleValue = values?.people?.value || 'N/A'
  const dateValue = values?.date ? new Date(values.date) : new Date()
  const timeValue = values?.timeObject || new Date()
  const phoneValue = values?.phone?.countryCode
    ? `+${values.phone.countryCode}${values.phone.areaCode}${values.phone.phoneNumber}`
    : 'N/A'
  const emailValue = values?.email || 'N/A'
  const nameValue = values?.name || 'N/A'
  const commentsValue = values?.comments || ''

  return (
    <Styling>
      <div className="header">
        <h2 style={{ textAlign: 'center' }}>
          {intl.messages['reserve.steps.three.title']}
        </h2>
        <div className="info">
          <p>{intl.messages['reserve.steps.three.description']}</p>
        </div>
      </div>
      <p>{intl.messages['reserve.steps.three.span1']}</p>
      <p>
        {intl.messages['reserve.steps.three.span2']}
        <span>{peopleValue} </span> {intl.messages['reserve.steps.three.span3']}{' '}
        <span>
          <FormattedDate
            value={dateValue}
            day="numeric"
            month="long"
            year="numeric"
          />
        </span>{' '}
        {intl.messages['reserve.steps.three.span4']}{' '}
        <span>
          <FormattedTime value={timeValue} hour="numeric" minute="numeric" />
        </span>
      </p>
      <p>
        {intl.messages['reserve.steps.three.span5']} <span>{phoneValue}</span> &{' '}
        <span>{emailValue}</span>. <br />
        <HighlightStyled>
          {intl.messages['reserve.steps.three.span6']}
        </HighlightStyled>
      </p>
      {values.comments && (
        <p>
          {intl.messages['reserve.steps.three.span7']}{' '}
          <span>{commentsValue}</span>
        </p>
      )}
      <p>
        {intl.messages['reserve.steps.three.span8']}
        <br />
        <span>{nameValue}</span>
      </p>
    </Styling>
  )
}
