import React from 'react'
import styled from 'styled-components'
import { colors, device } from '../../../assets/Styles'

const Styling = styled.button`
  font-size: 90%;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.14), 0 1px 7px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -1px rgba(0, 0, 0, 0.2);
  text-decoration: none;
  color: #fff;
  background: ${colors.dark1};
  text-align: center;
  letter-spacing: 0.5px;
  cursor: pointer;
  position: relative;
  outline: none;
  display: inline-block;
  overflow: hidden;
  user-select: none;
  vertical-align: middle;
  transition: 0.3s ease-out;
  border: none;
  border-radius: 2px;
  height: 36px;
  float: right;
  line-height: 36px;
  padding: 0 16px;
  &:hover {
    background-color: ${colors.dark4};
  }
  @media ${device.tablet} {
    width: 100%;
    float: none;
  }
`

export default function Button({ name, color, type = 'submit', onClick }) {
  // Function to handle click and scroll to top
  const handleClick = (e) => {
    if (onClick) onClick(e) // Execute the passed onClick function first
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth', // Smooth scrolling to the top
      })
    }, 100) // Delay the scroll slightly to ensure it happens after any re-renders
  }

  return (
    <Styling color={color} type={type} className="button" onClick={handleClick}>
      {name}
    </Styling>
  )
}
