import React from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import { colors, media } from '../../../assets/Styles'
import { Field } from 'formik'
import TimePickerWithFormik from './TimePickerWithFormik'
import DatePickerWithFormik from './DatePickerWithFormik'
import SelectWithFormik from './SelectWithFormik'

const Styling = styled.div.attrs({
  className: 'form-container',
})`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  position: absolute;
  .ant-select {
    width: 100%;
    height: 54px;
  }
  .ant-select-selector {
    background: white;
    border: 1px solid rgb(170, 170, 170) !important;
    transition-property: border;
    transition-duration: 0.2s;
    border-radius: 2px;
    padding: 8px 10px;
    color: hsl(0, 0%, 50%);
  }
  .ant-picker {
    background: white;
    width: 100%;
    height: 54px;
    border: 1px solid rgb(170, 170, 170);
    transition-property: border;
    transition-duration: 0.2s;
    border-radius: 2px;
    padding: 8px 10px;
    color: hsl(0, 0%, 50%);
  }

  .label {
    font-size: 90%;
    margin-bottom: 0.6rem;
    color: ${colors.black};

    span {
      color: ${colors.red};
    }
  }

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 1rem 0 3rem 0;

    ${media.phone`
       margin: 0 0 1rem 0;;
    `}
    h1 {
      text-align: center;
      font-size: 200%;
      ${media.phone`
         font-size: 230%;
      `}
    }

    .info {
      padding: 1rem;
      background: #fff;
      margin: 0;

      ${media.phone`
        margin: 0 0 2rem 0;
      `}
      a {
        border-bottom: 1px dotted;
      }

      p {
        text-align: left;
        margin-bottom: 0.5rem;
        font-size: 87%;
        width: fit-content;
      }

      .important {
        position: relative;
        display: inline-block;
        z-index: 1;

        &:before {
          content: '';
          z-index: -1;
          content: ' ';
          display: block;
          height: 90%;
          width: 100%;
          margin-left: -3px;
          margin-right: -3px;
          position: absolute;
          background: #fff2ae;
          transform: rotate(2deg);
          top: -1px;
          left: -1px;
          border-radius: 20% 25% 20% 24%;
          padding: 10px 3px 3px 10px;
        }
      }
    }
  }

  .row-container {
    display: flex;
    width: 100%;
    ${media.phone`
      flex-direction: column;
    `}
  }

  .field-wrapper {
    margin-right: 1rem;
    margin-bottom: 30px;
    position: relative;

    ${media.phone`
       margin: 0 0 10px 0;
    `}
    .error-wrapper {
      min-height: 1.2rem;
      margin-top: 0.2rem;

      span {
        background: ${colors.yellow};
        border-radius: 3px;
        padding: 0 0.3rem;
        font-size: 90%;
      }
    }

    .width-33 {
      &:first-child {
        margin-left: 0;
      }
    }
  }

  .last-child {
    margin-right: 0;
  }

  .width-33 {
    flex: 0 1 33%;
  }
`
const people = Array.from({ length: 8 }, (_, i) => {
  return { value: i + 1, label: (i + 1).toString() }
})

export default function Reservation({
  errors,
  touched,
  values,
  handleChange,
  handleBlur,
  setFieldTouched,
  setFieldValue,
  meta,
  field,
}) {
  const intl = useIntl()
  return (
    <Styling>
      <div className="header">
        <h1>{intl.messages['reserve.steps.one.title']}</h1>
        <div className="info">
          <p>
            <div className="important">
              {intl.messages['reserve.steps.one.description.span0']}
            </div>
          </p>
          <p>
            {intl.messages['reserve.steps.one.description.span1']}{' '}
            {intl.messages['reserve.steps.one.description.span2']}{' '}
            <a href="tel:+302645041769">+302645041769</a>{' '}
            {intl.messages['reserve.steps.one.description.span3']}
          </p>
          <p>
            {intl.messages['reserve.steps.one.description.span4']}{' '}
            <div className="important">
              {intl.messages['reserve.steps.one.description.span5']}
            </div>
          </p>
          <p>{intl.messages['reserve.steps.one.description.span6']}</p>
        </div>
      </div>
      <div className="row-container">
        <div className="field-wrapper width-33">
          <div className="label">
            {intl.messages['reserve.people.label']} <span>*</span>
          </div>
          <Field name="people" component={SelectWithFormik} people={people} />
          <div className="error-wrapper">
            {errors.people && touched.people && <span>{errors.people}</span>}
          </div>
        </div>
        <div className="field-wrapper width-33">
          <div className="label">
            {intl.messages['reserve.date.label']} <span>*</span>
          </div>
          <Field component={DatePickerWithFormik} />
          <div className="error-wrapper">
            {errors.date && touched.date && <span>{errors.date}</span>}
          </div>
        </div>
        <div className="field-wrapper width-33 last-child">
          <div className="label">
            {intl.messages['reserve.time.label']} <span>*</span>
          </div>
          <Field component={TimePickerWithFormik} />
          <div className="error-wrapper">
            {errors.time && touched.time && <span>{errors.time}</span>}
          </div>
        </div>
      </div>
    </Styling>
  )
}
