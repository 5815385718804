import React from 'react'
import styled from 'styled-components'
import { colors, media } from '../../../assets/Styles'
import { Field, useField } from 'formik'
import { useIntl } from 'react-intl'
import PhoneInputWithFormik from './PhoneInputWithFormik'

const Styling = styled.div.attrs({
  className: 'form-container',
})`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  .label {
    font-size: 90%;
    margin-bottom: 0.6rem;
    color: ${colors.black};

    span {
      color: ${colors.red};
    }
  }
  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 1rem 0 3rem 0;
    ${media.phone`
        margin: 0 0 1rem 0;;
    `}
    h1 {
      text-align: center;
      ${media.phone`
        font-size: 230%;
      `}
    }
    .info {
      padding: 1rem;
      background: #fff;
      margin: 0;

      ${media.phone`
        margin: 0 0 2rem 0;
      `}
      a {
        border-bottom: 1px dotted;
      }
      p {
        text-align: center;
        font-size: 87%;
      }
    }
    .items-wrapper {
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      display: flex;
      flex-direction: row;
      flex: 1;
      margin-top: 2rem;
      ${media.phone`
        flex-direction: column;
      `}

      .item {
        /* background: ${colors.light1}; */
        /* border: 1px solid ${colors.light6}; */
        display: flex;
        flex-direction: column;
        flex: 1;
        font-style: italic;
        appearance: none;
        padding: 1rem;
        margin: 0 1rem 0 0;
        text-align: center;
        ${media.phone`
          margin: 0.5rem 0;
          width: 100%;
        `}

        &:last-child {
          margin-right: 0;
        }
        span {
          font-size: 80%;
          font-style: normal;
        }
      }
    }
  }

  .form-title {
    margin: 0 1rem;
  }
  .ant-input {
    border: none !important;
  }
  .ant-phone-input-wrapper {
    border: 1px solid ${colors.light6};
    border-radius: 2px;
    transition-property: border;
    transition-duration: 0.2s;
    border: 1px solid ${colors.light6};
    &:hover {
      border: 1px solid rgb(68, 68, 68);
    }
  }
  .ant-input-group-wrapper-outlined {
    .ant-input-group-addon {
      border: unset;
    }
  }
  .row-container {
    display: flex;
    width: 100%;
    height: 100px;
    height: auto;
    margin: 0.5rem 0;
    width: 100%;
    ${media.phone`
      flex-direction: column;
    `}
    flex-direction: row;
  }

  .field-wrapper {
    margin-bottom: 30px;
    margin-right: 1rem;
    position: relative;
    ${media.phone`
      margin: 0 0 10px 0;
    `}
    input {
      font-size: 14.4px;
      line-height: 1.15;
      appearance: none;
      transition-property: border;
      transition-duration: 0.2s;
      border: 1px solid ${colors.light6};
      border-radius: 2px;
      padding: 15px 10px;
      outline: none;
      appearance: none;
      &:focus,
      &:hover {
        border: 1px solid ${colors.dark2};
      }
      width: 100%;
    }
    .error-wrapper {
      min-height: 1.2rem;
      margin-top: 0.2rem;
      span {
        background: ${colors.yellow};
        border-radius: 3px;
        padding: 0 0.3rem;
        font-size: 90%;
      }
    }
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  .width-33 {
    flex: 0 1 33%;
  }

  .width-50 {
    flex: 0 1 50%;
  }
  .width-100 {
    flex: 0 1 100%;
  }

  .container {
    span {
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.9;
      letter-spacing: normal;
    }
  }

  textarea {
    font-size: 90%;
    font-style: italic;
    transition-property: border;
    transition-duration: 0.2s;
    resize: none;
    appearance: none;
    border: 1px solid ${colors.light6};
    border-radius: 2px;
    padding: 10px 10px;
    outline: none;
    width: 100%;
    &:focus,
    &:hover {
      border: 1px solid ${colors.dark2};
    }
  }
`

const TextField = ({ label, required, ...props }) => {
  const [field, meta] = useField(props)
  return (
    <React.Fragment>
      <div className="label">
        {label} {require ? <span>*</span> : undefined}
      </div>
      <input className="input" {...field} {...props} />
      <div className="error-wrapper">
        {meta.touched && meta.error ? <span>{meta.error}</span> : null}
      </div>
    </React.Fragment>
  )
}

const MyTextArea = ({ ...props }) => {
  const [field, meta] = useField(props)
  return (
    <>
      <textarea className="text-area" {...field} {...props} />
      <div className="error-wrapper">
        {meta.touched && meta.error ? <span>{meta.error}</span> : null}
      </div>
    </>
  )
}

export default function Info({
  errors,
  touched,
  values,
  handleChange,
  handleBlur,
  setFieldTouched,
  setFieldValue,
  meta,
  field,
}) {
  const intl = useIntl()
  return (
    <Styling>
      <div className="header">
        <h1>{intl.messages['reserve.steps.two.title']}</h1>
        <div className="info">
          <p>{intl.messages['reserve.steps.two.description']}</p>
        </div>
      </div>
      <div className="row-container">
        <div className="field-wrapper width-33">
          <TextField
            require
            name="name"
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            label={intl.messages['reserve.name.label']}
            placeholder={intl.messages['reserve.name.placeholder']}
            value={values.name}
          />
        </div>

        <div className="field-wrapper width-33">
          <TextField
            require
            name="email"
            type="email"
            label={intl.messages['reserve.email.label']}
            placeholder={intl.messages['reserve.email.placeholder']}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
          />
        </div>
        <div className="field-wrapper width-33 last-child">
          <div className="label">
            {intl.messages['reserve.time.label']} <span>*</span>
          </div>
          <Field name="phone" component={PhoneInputWithFormik} />
          <div className="error-wrapper">
            {errors.phone &&
              errors.phone.phoneNumber &&
              touched.phone.phoneNumber && (
                <span>{errors.phone.phoneNumber}</span>
              )}
          </div>
        </div>
      </div>
      <div className="row-container">
        <div className="field-wrapper width-100">
          <div className="label">{intl.messages['reserve.comments.label']}</div>
          <MyTextArea
            name="comments"
            rows="6"
            cols="60"
            label={intl.messages['reserve.comments.placeholder']}
            placeholder={intl.messages['reserve.comments.placeholder']}
          />
        </div>
      </div>
    </Styling>
  )
}
