import React from 'react'
import styled from 'styled-components'
import { device } from '../../../assets/Styles'
import Button from './Button'
import Close from '../../../assets/Close'
import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import LanguageSwitcher from '../../shared/LanguageSwitcher'

const Styling = styled.div.attrs({
  className: 'footer-wrapper',
})`
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .footer-container {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    div {
    }
    .button-left {
    }
    .button-right {
    }
    .item {
      margin: 0 auto;
      .language-wrapper {
        li {
          display: inline-block;
          a {
            width: auto;
            &:first-child {
              margin-right: 0.5rem;
            }
            float: left;

            &.active {
              border-left: none;
              border-bottom: 1px solid #222;
            }
          }
        }
      }
    }
  }

  .pagination-wrapper {
    .pagination-container {
      nav {
        ul {
          margin: 0;
          display: flex;
          padding: 0;
          flex-wrap: wrap;
          list-style: none;
          align-items: center;
          @media ${device.phone} {
            justify-content: center;
          }
          li {
            height: 32px;
            @media ${device.phone} {
              margin: 0 10px;
            }
            margin: 0 3px;
            padding: 0 10px;
            line-height: 2rem;
            border: 1px solid;
            transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
              background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            &.active {
              background: #00140f;
              color: white;
            }
          }
        }
      }
    }
  }
`

export default function Footer({ page, previous, status }) {
  const history = useHistory()
  const intl = useIntl()
  // const Pagination = ({ page }) => {
  //   return (
  //     <div className="item pagination">
  //       <div className="pagination-wrapper">
  //         <div className="pagination-container">
  //           <nav>
  //             <ul>
  //               {[1, 2, 3].map((x) => (
  //                 <li key={x} className={x === page + 1 ? 'active' : ''}>
  //                   {x}
  //                 </li>
  //               ))}
  //             </ul>
  //           </nav>
  //         </div>
  //       </div>
  //     </div>
  //   )
  // }

  // Function to scroll to the top and move to the next step
  const handleNextClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
    // Logic to move to the next step
    console.log('Next button clicked')
  }

  return (
    <Styling>
      {/* <Pagination /> */}
      {page === 0 && (
        <div className="footer-container">
          <Close color="black" close={() => history.push('index')} />
          <div className="item">
            <div className="language-wrapper">
              <LanguageSwitcher />
            </div>
          </div>
          <div className="button-right">
            <Button
              name={intl.messages['reserve.buttons.next']}
              onClick={handleNextClick}
            ></Button>
          </div>
        </div>
      )}
      {page === 1 && (
        <div className="footer-container">
          <div className="button-previous">
            <Button
              onClick={previous}
              type="button"
              name={intl.messages['reserve.buttons.previous']}
            />
          </div>
          <div className="button-right">
            <Button
              name={intl.messages['reserve.buttons.next']}
              onClick={handleNextClick}
            ></Button>
          </div>
        </div>
      )}
      {page === 2 && (
        <div className="footer-container">
          <div className="button-previous">
            <Button
              previous={previous}
              type="button"
              name={intl.messages['reserve.buttons.previous']}
            />
          </div>

          <div className="button-right">
            <Button
              name={
                status === 'REQUEST_SUBMIT'
                  ? intl.messages['reserve.buttons.submitting']
                  : intl.messages['reserve.buttons.submit']
              }
              onClick={handleNextClick}
            ></Button>
          </div>
        </div>
      )}
    </Styling>
  )
}
