import React from 'react'
import PhoneInput from 'antd-phone-input'

const PhoneInputWithFormik = ({
  field, // { name, value, onChange, onBlur }
  form: { setFieldValue, setFieldTouched, errors, touched },
  ...props
}) => {
  const handlePhoneChange = (value) => {
    setFieldValue(field.name, value)
    setFieldTouched(field.name, true, false)
  }

  return (
    <div>
      <PhoneInput
        countryCodeEditable={true}
        disableParentheses
        value={field.value || {}}
        onChange={handlePhoneChange}
        {...props}
      />
    </div>
  )
}

export default PhoneInputWithFormik
