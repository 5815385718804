import React from 'react'
import { TimePicker } from 'antd'
import { useIntl } from 'react-intl'
import dayjs from 'dayjs'

const TimePickerWithFormik = ({
  id,
  form: { setFieldValue, setFieldTouched, values },
  field,
  ...props
}) => {
  const handleDateChange = (time) => {
    if (time) {
      const timeString = time.format('HH:mm')
      // timeString is for Airtable, in order to use the absolute Time
      setFieldValue('time', timeString)

      // timeObject is for Make and for the form to display formatting properly
      setFieldValue('timeObject', time)
      setFieldTouched('time', '')
    } else {
      // Handle case where time is cleared
      setFieldValue('time', '')
      setFieldValue('timeObject', null)
      setFieldTouched('time', true, false)
    }
  }

  const intl = useIntl()

  return (
    <TimePicker
      id={id}
      className="timepicker"
      value={values.timeObject ? dayjs(values.timeObject) : null}
      onChange={handleDateChange}
      format="HH:mm"
      disabledHours={() => [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
      minuteStep={10}
      hideDisabledOptions={true}
      showNow={true}
      placeholder={intl.messages['reserve.time.placeholder']}
      changeOnScroll={false}
      needConfirm={false}
    />
  )
}

export default TimePickerWithFormik
