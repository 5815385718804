import React from 'react'
import styled from 'styled-components'
import {
  CloudinaryContext,
  Image,
  Placeholder,
  Transformation,
} from 'cloudinary-react'

const StyledImage = styled.div`
  width: 100%;
  img {
    width: ${(props) => props.width || '100%'};
    height: ${(props) => props.height || '100%'};
    transition: filter 1s ease-in-out;
    display: block;
    margin: 0 auto;
  }
`

export const BlurImageLoader = ({ name, width, height, ...props }) => {
  return (
    <CloudinaryContext cloudName="dqtacqxog">
      <StyledImage width={width} height={height}>
        <Image
          publicId={name}
          loading="lazy"
          dpr="auto"
          responsive
          width="auto"
          crop="scale"
          responsiveUseBreakpoints="true"
          {...props}
        >
          <Placeholder type="blur" />
          <Transformation quality="auto:good" fetchFormat="auto" />
        </Image>
      </StyledImage>
    </CloudinaryContext>
  )
}
